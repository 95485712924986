<template>
    <div>
		<chat-received-attachment-component
			v-for="(attachment, index) in receivedattachments"
			:key="index"
			:attachment="attachment" :index="index"></chat-received-attachment-component>
	</div>
</template>

<script>
    import Event from '../event.js';

    export default {
		props: ["receivedattachments"],
    }
</script>
