<template>
    <div class="card-body msg_card_body">
        <chat-message-component
			v-for="(message, index) in messages"
            :key="message.id"
            :message="message"
            :lastmessage_at="messages[index-1] ? messages[index-1].created_at : null"
            :lastmessage_from="messages[index-1] ? messages[index-1].from : null"
            ></chat-message-component>
    </div>
</template>

<script>
    import Event from '../event.js';

    export default {
        data() {
            return {
                messages: [],
                lastScrollPos: 0,
                lastScrollOffsetBottom: 0,
                isLoadingPrevMsgs: false
            }
        },
        /*updated: function () {
            this.$nextTick(function () {
                this.scrollToBottom(100);
                if (document.querySelectorAll('.message_attachment').length > 0) {
                    this.scrollToBottom(700);
                }
            })
        },*/
        mounted() {
            this.getMessages();
            Event.$on('added_message', (message) => {
                this.messages.push(message);
                this.scrollToBottom((message.attachments && message.attachments.length > 0 ? 600 : 100));
            });
            setTimeout(this.getUnreadMessages, 5000);
            var vueTarget = this;
            this.$el.addEventListener('scroll', function() {
                if (vueTarget.$el.scrollTop < 150 && vueTarget.lastScrollPos > vueTarget.$el.scrollTop && !vueTarget.isLoadingPrevMsgs) {
                    vueTarget.isLoadingPrevMsgs = true;
                    vueTarget.lastScrollOffsetBottom = vueTarget.$el.scrollHeight-(vueTarget.$el.scrollTop-30);
                    axios.post('/chat/getMessages', { maxid: vueTarget.messages[0].id }).then((response) => {
                        $.each(response.data, function (i, message) {
                            vueTarget.messages.unshift(message);
                        });
                        if (response.data.length) {
                            setTimeout(function() {
                                vueTarget.$el.scrollTop = vueTarget.$el.scrollHeight-vueTarget.lastScrollOffsetBottom;
                                vueTarget.isLoadingPrevMsgs = false;
                            }, 50);
                        }
                    });
                }
                vueTarget.lastScrollPos = vueTarget.$el.scrollTop;
            });
        },
        methods: {
            scrollToBottom(timeout) {
                var container = this.$el;
                setTimeout(function(){
                    container.scrollTop = container.scrollHeight;
                }, timeout);
            },
            getMessages() {
                axios.post('/chat/getMessages').then((response) => {
                    this.messages = response.data;
                    this.$nextTick(function () {
                        this.scrollToBottom(100);
                        if (document.querySelectorAll('.message_attachment').length > 0) {
                            this.scrollToBottom(1200);
                        }
                    })
                });
            },
            getUnreadMessages() {
                axios.get('/chat/getUnreadMessages').then((response) => {
                    $.each(response.data, function (i, message) {
                        Event.$emit("added_message", message);
                    });
                    if (document.getElementById('chat')) {
                        setTimeout(this.getUnreadMessages, 5000);
                    }
                });
            }
        }
    }
</script>