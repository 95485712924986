var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-body msg_card_body" },
    [
      _vm._l(_vm.messages, function (message, index) {
        return _c("chat-message-component", {
          key: message.id,
          attrs: {
            message: message,
            lastmessage_at: _vm.messages[index - 1]
              ? _vm.messages[index - 1].created_at
              : null,
            lastmessage_from: _vm.messages[index - 1]
              ? _vm.messages[index - 1].from
              : null,
          },
        })
      }),
      _vm._v(" "),
      _vm.showTypingIndicator
        ? _c("div", { staticClass: "typing-indicator" }, [
            _vm._v(
              "\n            Leonie " +
                _vm._s(_vm.lang.common.is_typing) +
                _vm._s(_vm.dots) +
                "\n        "
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }