lpcms.get_accountdata = function() {

	var url = '/profile/getData';
	if (typeof Intl != "undefined" && typeof Intl.DateTimeFormat().resolvedOptions().timeZone != "undefined") {
		url += '?tz='+Intl.DateTimeFormat().resolvedOptions().timeZone;
	}

	axios.get(url).then(function(response) {
		var d = response.data;
		if (d) {
			if (d.unread_msgs > 0 && document.location.pathname != "/mailer") {
				$('#siteheader-navi-btn-mail .badge, .mobile-mainmenu-linklist-mailer .badge, #siteheader-mobile .badge-info-red').show();
			} else {
				$('#siteheader-navi-btn-mail .badge, .mobile-mainmenu-linklist-mailer .badge, #siteheader-mobile .badge-info-red').hide();
			}
			if (d.coins) {
				$('#siteheader-navi-menu-profile-accountinfos .coins span, #mobile-mainmenu-accountinfos .coins span').text(d.coins.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."));
			}
		}
		window.Laravel.user.credits = d.coins;
		setTimeout(function() {
			lpcms.get_accountdata();
		}, 5000);
	});
};
lpcms.get_downloaded_vids = () => {
	return new Promise((resolve,reject) => {
		caches.open('downloads').then(cache => {
			cache.matchAll().then(matches => {
				var keys = [];
				if (matches && typeof matches != "undefined") {
					for (var match of matches) {
						if (typeof match.url != "undefined") {
							var matches2 = match.url.match(/b-cdn.net\/(.+)\/play_/);
							if (matches2) {
								keys.push(matches2[1]);
							}
						}
					}
				}
				resolve(keys);
			}).catch((err) => {
				console.log('dlerr 1', err);
				reject('get dl videos err 1');
			});
		}).catch((err) => {
			console.log('dlerr 2', err);
			reject('get dl videos err 2');
		});
	});
};
lpcms.cache_offline_videos = (loop) => {
	if (lpcms.is_online) {
		axios.get('/purchases/videos', { params: { no_ajax: 'true', offlinemode: 'true' } });
		lpcms.get_downloaded_vids().then((vids) => {
			if (vids.length > 0) {
				axios.get('/purchases/videos', {
					params: {
						no_ajax: 'true',
						offlinemode: 'true',
						vids: vids
					}
				}).then((r) => {
					var body = r.data;
					axios.get('https://cdn.leonie-pur.com/css/'+window.Laravel.cacheHash+'/video-js.css');
					axios.get('https://cdn.leonie-pur.com/css/'+window.Laravel.cacheHash+'/videojs.thumbnails.css');
					axios.get('https://cdn.leonie-pur.com/css/'+window.Laravel.cacheHash+'/videojs.airplay.css');
					axios.get('https://cdn.leonie-pur.com/js/'+window.Laravel.cacheHash+'/video.js');
					axios.get('https://cdn.leonie-pur.com/js/'+window.Laravel.cacheHash+'/videojs.thumbnails.js');
					axios.get('https://cdn.leonie-pur.com/js/'+window.Laravel.cacheHash+'/videojs.theaterMode.js');
					axios.get('https://cdn.leonie-pur.com/js/'+window.Laravel.cacheHash+'/silvermine-videojs-quality-selector.min.js');
					axios.get('https://cdn.leonie-pur.com/js/'+window.Laravel.cacheHash+'/videojs.airplay.js');
					// $('.medialist__item a', body).not('.medialist__item-category').each((i,a) => {
					// 	$('img, video', a).each((j,med) => {
					// 		axios.get($(med).attr('src'));
					// 	});
					// 	axios.get($(a).attr('href'));
					// });
				});
			}
		});
	}
	if (loop) {
		setTimeout(function() {
			lpcms.cache_offline_videos(true);
		}, 60000);
	}
};
lpcms.premium.addPostClickHandlers = function(src) {
	$('.lvrs__postcomment__showall', src).off('click').on('click', function() {
		var hidden_comments = $(this).parents('.lvrs__post').find('.lvrs__postcomment.hidden');
		if (hidden_comments.length > 0) {
			hidden_comments.removeClass('hidden');
			$(this).text($(this).data('textvisible'));
		} else {
			$(this).parents('.lvrs__post').find('.lvrs__postcomment:gt(2)').addClass('hidden');
			$(this).text($(this).data('texthidden'));
		}
	});
	$('button.lvrs__sendcomment', src).off('click').on('click', function() {
		var hidden_comments = $(this).parents('.lvrs__post').find('.lvrs__postcomment.hidden');
		if (hidden_comments.length > 0) {
			$(this).parents('.lvrs__post').find('.lvrs__postcomment__showall').trigger('click');
		}
		var clickedbtn = $(this);
		axios.post('/lovers/'+$(this).data('postid')+'/addComment', {
			content: $(this).parent().find('textarea').val()
		}).then((r) => {
			var body = r.data;
			if (body.error) {
				lpcms.alert(body.error);
			} else {
				var datatargets = $('[data-postid='+clickedbtn.data('postid')+']');
				datatargets.data('commentcount', body.commentcount);
				$(clickedbtn).parents('.lvrs__post').find('.lvrs__postinteractives-comments span').text(body.commentcount+' '+(body.commentcount != 1 ? Laravel.lang.common.comments : Laravel.lang.common.comment))
				$('<div class="lvrs__postcomment">').html('<span><b>'+window.Laravel.user.name+'</b> '+Laravel.lang.common.wrote+':</span><p>'+$(clickedbtn).parent().find('textarea').val()+'</p>').insertAfter($(clickedbtn).parents('.lvrs__post').find('.lvrs__postcomment:last'));
				$(clickedbtn).parent().find('textarea').val('');
				$(clickedbtn).parents('.lvrs__post').trigger('focus');
			}
		});
	});
	$('.lvrs__postinteractives-likebtn', src).off('click').on('click', function() {
		lpcms.premium.like_post(this);
	});
};

lpcms.premium.addLightboxBottomData = function(lightbox, srcelem) {
	$(lightbox).find('video,img').off('contextmenu').on('contextmenu', function(e) {
		e.preventDefault();
		return false;
	}).off('dragstart').on('dragstart', function(e) {
		e.preventDefault();
		return false;
	});
	$('.lightbox-bottom .lvrs__postinteractives .lvrs__postinteractives-comments span', lightbox).text($(srcelem).data('commentcount')+' '+($(srcelem).data('commentcount') != 1 ? Laravel.lang.common.comments : Laravel.lang.common.comment));
	$('.lightbox-bottom .lvrs__postinteractives .lvrs__postinteractives-comments', lightbox).off('click').on('click', function() {
		$.featherlight.close();
		if ($('.lvrs__grid').length > 0) {
			lpcms.load_via_ajax('/lovers/'+$(srcelem).data('postid')+'/view?frompage='+lpcms.premium.currentpage+'&source='+lpcms.premium.mediatype+'&frompost='+$(srcelem).data('postid')).then(function() { document.location.hash = 'post-'+$(srcelem).data('postid')+'-comments'; });
		} else {
			$("html, body").animate({ scrollTop: $('#lvrs__post-'+$(srcelem).data('postid')+' .lvrs__postinteractives').position().top-50 }, "slow");
		}
	});

	$('.lightbox-bottom .lvrs__postinteractives .lvrs__postinteractives-likebtn span', lightbox).text($(srcelem).data('likecount')+' '+($(srcelem).data('likecount') != 1 ? Laravel.lang.common.likes : Laravel.lang.common.like));
	$('.lightbox-bottom .lvrs__postinteractives .lvrs__postinteractives-likebtn', lightbox).attr('data-postid', $(srcelem).data('postid')).data('postid', $(srcelem).data('postid')).off('click').on('click', function() {
		lpcms.premium.like_post(this).then(function(r) {
			$(srcelem).data('likecount', r.data.likes);
			$(srcelem).data('liked', r.data.liked);
		});
	});

	if ($(srcelem).data('liked')) {
		$('.lightbox-bottom .lvrs__postinteractives .lvrs__postinteractives-likebtn', lightbox).addClass('active');
	} else {
		$('.lightbox-bottom .lvrs__postinteractives .lvrs__postinteractives-likebtn', lightbox).removeClass('active');
	}
	lpcms.premium.parsePostMedia(lightbox);
};
lpcms.premium.like_post = (btn) => {
	var clickedbtn = $(btn);
	if (lpcms.premium.hasSubscription) {
		return axios.post('/lovers/'+clickedbtn.data('postid')+'/toggleLike').then((r) => {
			var data = r.data;
			var targets = $('.lvrs__postinteractives-likebtn[data-postid='+clickedbtn.data('postid')+']');
			var datatargets = $('[data-postid='+clickedbtn.data('postid')+']');
			if (data.liked) {
				$(targets).addClass('active');
				datatargets.data('liked', 'true');
			} else {
				$(targets).removeClass('active');
				datatargets.data('liked', 'false');
			}
			$(targets).find('span').text(data.likes+' '+(data.likes != 1 ? Laravel.lang.common.likes : Laravel.lang.common.like));
			datatargets.data('likecount', data.likes);
			return r;
		});
	} else {
		lpcms.premium.clicked_but_not_subscribed();
	}
};
lpcms.premium.addLightbox = function(src) {
	$('a', src).featherlightGallery({
		nextIcon: '	&#8250;',
		previousIcon: '	&#8249;',
		targetAttr: 'data-lightboxcontent',
		afterContent: function() {
			lpcms.premium.addLightboxBottomData($('.featherlight-content', this.$instance), $(this.$currentTarget).parent());
		}
	});
};
(function($) {
	if (window.Laravel && window.Laravel.user && window.Laravel.user.authenticated) {
		lpcms.get_accountdata();
		lpcms.cache_offline_videos(true);
	}
})(jQuery);

window.Vue = require('vue').default;
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import VueClickaway from "vue-clickaway";
import VEmojiPicker from 'v-emoji-picker';
import CoolLightBox from 'vue-cool-lightbox';
Vue.use(VEmojiPicker);
Vue.use(CoolLightBox);

Vue.directive('clickaway', VueClickaway.directive);
Vue.component('chat-component', require('./components/ChatComponent.vue').default);
Vue.component('chat-messages-component', require('./components/ChatMessagesComponent.vue').default);
Vue.component('chat-message-component', require('./components/ChatMessageComponent.vue').default);
Vue.component('chat-message-attachment-component', require('./components/ChatMessageAttachmentComponent.vue').default);
Vue.component('chat-form-component', require('./components/ChatFormComponent.vue').default);
Vue.component('chat-received-attachments-component', require('./components/ChatReceivedAttachmentsComponent.vue').default);
Vue.component('chat-received-attachment-component', require('./components/ChatReceivedAttachmentComponent.vue').default);
Vue.component('chat-loggedout-component', require('./components/ChatLoggedoutComponent.vue').default);
Vue.component('chat-messages-loggedout-component', require('./components/ChatMessagesLoggedoutComponent.vue').default);
Vue.component('chat-form-loggedout-component', require('./components/ChatFormLoggedoutComponent.vue').default);