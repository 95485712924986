var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "msg_wrapper msg_media" }, [
    _c("div", { staticClass: "msg_container" }, [
      _c("div", { staticClass: "message_attachment lpcms-player" }, [
        _vm.attachment.censored &&
        (!_vm.current_user || _vm.current_user.id != null)
          ? _c(
              "a",
              {
                staticClass: "censor-overlay",
                attrs: { href: "javascript: void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.lpcms.show_modal("signup")
                  },
                },
              },
              [
                _c("span", { staticClass: "button-md ghost bright" }, [
                  _vm._v(_vm._s(_vm.lang.common.register_now)),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.lang.common.see_picture_uncensored) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.attachment.type == "image"
          ? _c("img", {
              staticClass: "attachment",
              attrs: { src: _vm.attachment.thumburl },
              on: {
                contextmenu: function ($event) {
                  return _vm.disableRightClick($event)
                },
                click: function ($event) {
                  return _vm.openLightbox([{ src: _vm.attachment.url }])
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.attachment.type == "video"
          ? _c(
              "video",
              {
                staticClass: "vjs-default-skin vjs-fill video-js",
                attrs: {
                  poster: _vm.attachment.thumburl,
                  preload: "metadata",
                  controls: "",
                  playsinline: "",
                },
              },
              [
                _c("source", {
                  attrs: {
                    src: _vm.attachment.url,
                    type: "application/x-mpegURL",
                  },
                }),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }