var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "chat-wrapper" } },
      [
        _c("CoolLightBox", {
          attrs: {
            items: _vm.lightbox_images,
            index: _vm.index,
            slideshow: false,
            loop: false,
          },
          on: {
            close: function ($event) {
              _vm.index = null
            },
            "on-open": _vm.onLightboxOpen,
          },
        }),
        _vm._v(" "),
        _vm.opened
          ? _c(
              "div",
              { staticClass: "card" },
              [
                _c("chat-messages-loggedout-component"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-footer" },
                  [_c("chat-form-loggedout-component")],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { id: "chat-attachment-list-right" } },
          [
            _c("h2", [_vm._v("Empfangene Dateien")]),
            _vm._v(" "),
            _c("chat-received-attachments-component", {
              attrs: { receivedattachments: _vm.receivedattachments },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    !_vm.opened
      ? _c(
          "div",
          {
            staticClass: "chat-closed",
            on: {
              click: function ($event) {
                return _vm.toggle(true)
              },
            },
          },
          [
            _vm._v("\n\t\tChat mit Leonie-Pur "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.unreadMessageCount > 0,
                    expression: "unreadMessageCount > 0",
                  },
                ],
                staticClass: "unread-messages badge badge-pill badge-info",
              },
              [_vm._v(_vm._s(_vm.unreadMessageCount))]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }