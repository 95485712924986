<template>
	<div>
		<div v-if="isSending" class="message_sending">
			<div class="loader"></div>Nachricht wird gesendet...
		</div>
		<form enctype="multipart/form-data" v-if="!isSending">
			<div id="chat-sendmessage">
				<span class="material-symbols-rounded add_circle" @click="openAttachmentUpload">add_circle</span>
				<span v-if="attachmentCount > 0" class="badge badge-pill badge-info">{{ attachmentCount }}</span>
				<textarea
					v-bind:rows="(windowwidth > 1000 ? '1' : '1')"
					placeholder="Gib eine Nachricht ein..."
					@keydown="typing"
					v-model="content"
					v-html="settext"
					autofocus
				></textarea>
				<div id="chat-textarea-buttons">
					<span class="material-symbols-rounded" @click="sendMessage">send</span>
				</div>
				<input
					type="file"
					name="attachment"
					accept="image/*, video/mp4, video/x-m4v, video/*"
					style="display: none;"
					ref="attachmentUpload"
					@change="filesChanged"
				>
			</div>
			<div id="chat-textarea-priceinfo" v-if="parseInt(price) > 0">
				<a id="chat-textarea-paymentlink" href="#payment" @click="lpcms.payment.dialog();">
					Jetzt Guthaben aufladen und kostenlos schreiben
				</a>
				<div>
					Preis: {{ price }}
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import Event from "../event.js";

export default {
	props: ["settext", "price"],
	data() {
		return {
			content: this.settext,
			isSending: false,
			attachmentCount: 0,
			showEmojiPicker: false,
			lastMsgContent: '',
			windowwidth: $(window).width()
		};
	},
	mounted() {
		this.autogrow();
		this.settext = null;
	},
	created() {
		window.addEventListener("resize", this.windowresized);
	},
	destroyed() {
		window.removeEventListener("resize", this.windowresized);
	},
	methods: {
		autogrow() {
			if (this.windowwidth > 1000) {
				jQuery('#chat-sendmessage textarea').focus().autogrow(16,90);
			} else {
				jQuery('#chat-sendmessage textarea').focus().autogrow(16,90);
			}
		},
		windowresized(e) {
			this.windowwidth = $(window).width();
			this.autogrow();
		},
		typing(e) {
			if (e.keyCode === 13 && e.shiftKey) {
				e.preventDefault();
				this.sendMessage();
			}
		},
		toggleEmojiPicker() {
			this.showEmojiPicker = !this.showEmojiPicker;
		},
		closeEmojiPicker(outside) {
			this.showEmojiPicker = false;
		},
		selectEmoji(emoji) {
			this.showEmojiPicker = false;
			var cursorPos = jQuery('textarea', this.$el).prop('selectionStart');
			if (!this.content) {
				this.content = emoji.data;
				return true;
			}
			var textBefore = this.content.substring(0,  cursorPos);
			var textAfter  = this.content.substring(cursorPos, this.content.length);

			this.content = textBefore + emoji.data + textAfter;
		},
		filesChanged() {
			this.attachmentCount = !this.$refs.attachmentUpload
				? 0
				: this.$refs.attachmentUpload.files.length;
		},
		openAttachmentUpload() {
			this.$refs.attachmentUpload.click();
		},
		sendMessage() {
			if (
				(!this.content || this.content.trim() === "") &&
				this.$refs.attachmentUpload.files.length <= 0
			) {
				return;
			}
			if (this.lastMsgContent != '' && this.lastMsgContent == this.content.trim()) {
				return;
			}
			this.isSending = true;
			/*var d = new Date();
				Event.$emit("added_message", {
					content: this.content.trim(),
					to: "1",
					from: window.Laravel.user.id,
					created_at:
						("0" + d.getHours()).slice(-2) +
						":" +
						("0" + d.getMinutes()).slice(-2)
				});*/

			let formData = new FormData();
			if (this.content) {
				formData.append("content", this.content.trim());
				this.lastMsgContent = this.content.trim();
			}
			formData.append("to", "1");
			for (var i = 0; i < this.$refs.attachmentUpload.files.length; i++) {
				formData.append(
					"attachments[]",
					this.$refs.attachmentUpload.files[i]
				);
			}
			axios
				.post("/chat/sendMessage", formData, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				})
				.catch((err) => {
					if (err && err.response && err.response.status) {
						if (err.response.status == 402) {
							lpcms.payment.dialog('Du hast nicht genügend Guthaben. Bitte lade dein Konto auf.');
						}
					}
					this.isSending = false;
				})
				.then(response => {
					if (
						response &&
						response.data &&
						response.data.success &&
						response.data.msg
					) {
						Event.$emit("added_message", response.data.msg);
					}
					this.content = null;
					this.settext = null;
					this.isSending = false;
					this.filesChanged();
					setTimeout(() => {
						document.querySelector("#chat-sendmessage textarea").focus();
					}, 500);
				});
		}
	}
};
</script>