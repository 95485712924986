var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["chat-received-attachment"] }, [
    _c("img", {
      attrs: { src: _vm.attachment.attachment.thumburl },
      on: {
        contextmenu: function ($event) {
          return _vm.disableRightClick($event)
        },
        click: function ($event) {
          return _vm.openLightbox([{ src: _vm.attachment.attachment.url }])
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }