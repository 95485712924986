<template>
	<div class="msg_wrapper msg_media">
		<div class="msg_container">
			<div class="message_attachment lpcms-player">
				<a href="javascript: void(0)" @click="lpcms.show_modal('signup');" class="censor-overlay" v-if="attachment.censored && (!current_user || current_user.id != null)">
					<span class="button-md ghost bright">{{ lang.common.register_now }}</span>
					<span>
						{{ lang.common.see_picture_uncensored }}
					</span>
				</a>
				<img @contextmenu="disableRightClick($event)" @click="openLightbox([{src: attachment.url}])" v-if="attachment.type == 'image'" :src="attachment.thumburl" class="attachment" />
				<video v-if="attachment.type == 'video'" :poster="attachment.thumburl" preload="metadata" class="vjs-default-skin vjs-fill video-js" controls playsinline>
					<source :src="attachment.url" type="application/x-mpegURL">
				</video>
			</div>
		</div>
	</div>
</template>

<script>
import Event from "../event.js";
export default {
	props: ["attachment", "msgcreated"],
	data() {
		return {
			current_user: window.Laravel.user,
			lang: window.Laravel.lang
		};
	},
	methods: {
		openLightbox(imgs) {
			Event.$emit("show_image_in_lightbox", imgs);
		},
		formatMessageTime(time) {
			var d = new Date(time.replace(' ', 'T')+'Z');
			return ("0" + d.getUTCHours()).slice(-2) + ":" + ("0" + d.getUTCMinutes()).slice(-2);
		},
		disableRightClick(e) {
			e.preventDefault();
		}
	}
};
</script>