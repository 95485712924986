var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isSending
      ? _c("div", { staticClass: "message_sending" }, [
          _c("div", { staticClass: "loader" }),
          _vm._v("Nachricht wird gesendet...\n\t"),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isSending
      ? _c("form", { attrs: { enctype: "multipart/form-data" } }, [
          _c("div", { attrs: { id: "chat-sendmessage" } }, [
            _c(
              "span",
              {
                staticClass: "material-symbols-rounded add_circle",
                on: { click: _vm.openAttachmentUpload },
              },
              [_vm._v("add_circle")]
            ),
            _vm._v(" "),
            _vm.attachmentCount > 0
              ? _c("span", { staticClass: "badge badge-pill badge-info" }, [
                  _vm._v(_vm._s(_vm.attachmentCount)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.content,
                  expression: "content",
                },
              ],
              attrs: {
                rows: _vm.windowwidth > 1000 ? "1" : "1",
                placeholder: "Gib eine Nachricht ein...",
                autofocus: "",
              },
              domProps: { value: _vm.content, innerHTML: _vm._s(_vm.settext) },
              on: {
                keydown: _vm.typing,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.content = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("div", { attrs: { id: "chat-textarea-buttons" } }, [
              _c(
                "span",
                {
                  staticClass: "material-symbols-rounded",
                  on: { click: _vm.sendMessage },
                },
                [_vm._v("send")]
              ),
            ]),
            _vm._v(" "),
            _c("input", {
              ref: "attachmentUpload",
              staticStyle: { display: "none" },
              attrs: {
                type: "file",
                name: "attachment",
                accept: "image/*, video/mp4, video/x-m4v, video/*",
              },
              on: { change: _vm.filesChanged },
            }),
          ]),
          _vm._v(" "),
          parseInt(_vm.price) > 0
            ? _c("div", { attrs: { id: "chat-textarea-priceinfo" } }, [
                _c(
                  "a",
                  {
                    attrs: {
                      id: "chat-textarea-paymentlink",
                      href: "#payment",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.lpcms.payment.dialog()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\tJetzt Guthaben aufladen und kostenlos schreiben\n\t\t\t"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _vm._v("\n\t\t\t\tPreis: " + _vm._s(_vm.price) + "\n\t\t\t"),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }