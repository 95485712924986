<template>
	<div>
		<div id="chat-wrapper">
			<CoolLightBox
				:items="lightbox_images"
				:index="index"
				:slideshow="false"
				:loop="false"
				@close="index = null"
				@on-open="onLightboxOpen">
				</CoolLightBox>
			<div class="card" v-if="opened">
				<!--
				<div class="card-header msg_head" @click="toggle(false)">
					<div class="d-flex bd-highlight">
						<div class="img_cont">
							<img
								src="https://cdn.leonie-pur.com/images/static/leonie_round_small.png"
								class="rounded-circle user_img"
							>
							<span class="online_icon"></span>
						</div>
						<div class="user_info">
							<span>Chat mit Leonie-Pur</span>
							<p>jetzt online</p>
						</div>
					</div>
				</div>-->

				<chat-messages-component></chat-messages-component>

				<div class="card-footer">
					<chat-form-component :price="price" :settext="settext"></chat-form-component>
				</div>
			</div>
			<div id="chat-attachment-list-right">
				<h2>Empfangene Dateien</h2>
				<chat-received-attachments-component :receivedattachments="receivedattachments"></chat-received-attachments-component>
				<a href="/purchases/chatMedia" class="show-all">Alle ansehen</a>
			</div>
		</div>
		<div class="chat-closed" v-if="!opened" @click="toggle(true)">
			Chat mit Leonie-Pur <span class="unread-messages badge badge-pill badge-info" v-show="unreadMessageCount > 0">{{ unreadMessageCount }}</span>
		</div>
	</div>
</template>

<script>
    import Event from '../event.js';

    export default {
		props: ["opened", "receivedattachments", "settext", "price"],
        data() {
            return {
				unreadMessageCount: 0,
				index: null,
				lightbox_images: []
            }
		},
		mounted() {
			//this.pollUnreadMessages();
			Event.$on('show_image_in_lightbox', (imgs) => {
                this.lightbox_images = imgs;
				this.index = 0;
            });
			this.settext = null;
		},
        methods: {
			pollUnreadMessages() {
				axios.get('/chat/getUnreadMessageCount').then((response) => {
					this.unreadMessageCount = response.data;
					setTimeout(this.pollUnreadMessages, 5000);
				});
			},
			toggle(open) {
				if (typeof open == "undefined") {
					open = !this.open;
				}
				this.opened = open;
				Event.$emit("chat_openclose", {
					isOpen: this.opened
				});
			},
			onLightboxOpen(index) {
				$('.cool-lightbox__slide__img img').on('contextmenu', function(e) {
					e.preventDefault();
				});
			}
        }
    }
</script>