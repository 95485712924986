<template>
	<div
		v-bind:class="['chat-received-attachment']"
	>
		<img @contextmenu="disableRightClick($event)" @click="openLightbox([{src: attachment.attachment.url}])" :src="attachment.attachment.thumburl" />
	</div>
</template>

<script>
import Event from '../event.js';
export default {
	props: ["attachment", "index", "Laravel"],
	data() {
		return {
			idx: null
		};
	},
	methods: {
		openLightbox(imgs) {
			Event.$emit("show_image_in_lightbox", imgs);
		},
		disableRightClick(e) {
			e.preventDefault();
		}
	}
};
</script>